#comunicador-expressivo-about-section{
    background: rgb(24,51,94);
    background: -moz-linear-gradient(172deg, rgba(24,51,94,1) 0%, rgba(2,2,2,1) 99%, rgba(3,3,3,1) 100%);
    background: -webkit-linear-gradient(172deg, rgba(24,51,94,1) 0%, rgba(2,2,2,1) 99%, rgba(3,3,3,1) 100%);
    background: linear-gradient(172deg, rgba(24,51,94,1) 0%, rgba(2,2,2,1) 99%, rgba(3,3,3,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#18335e",endColorstr="#030303",GradientType=1);

    .card-about{

        background-color: #FFFFFF;
        border-radius: 15px;
        padding: 1em;

        .img-box-about{
            width: auto;
            height: 38px;
            position: relative;
            margin-bottom: .6em;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        h5{
            color: #1E427A;
            font-size: 19px;
            line-height: 19px;
        }

        .description-box{
            height: 80px;
            p{
                color: #000000;
                font-size: 14px;
                line-height: 13px;
                font-weight: 300;
            }
        }

    }
}