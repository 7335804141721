#about-section-imersao-jose{
    background-color: #E5E5E5;

    .card-about{

        background-color: #B78C5A;
        border-radius: 15px;
        padding: 1em;

        .card-image-box{
            width: auto;
            height: 38px;
            position: relative;
            margin-bottom: .6em;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        span, p{
            font-size: 16px;
            color: #E5E5E5;
            line-height: 20px;
            text-align: justify;
        }

        h5{
            color: #531A08;
            font-size: 22px;
            line-height: 22px;
        }

    }
}