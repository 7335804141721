#social-section{
    background-color: #121212;

    h4{
        color: #B78C5A !important;
    }

    .card-depoimento{
        height: 440px;
        background-color: #2B2B2B;
        border-radius: 15px;
        padding: 1em;

        .image-box{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        h6{
            color: #B78C5A !important;
        }

        p{
            font-weight: 400;
        }
    }
}

@media screen and (max-width:780px) {
    #social-section{

        .card-depoimento{
            height: 640px;

        }
    }
}