#comunicador-expressivo-meetings-section{
    background-color: #FFFFFF;

    h3{
        color: #18335e;
        font-size: 32px;
        text-align: center;
    }

    .image-box{
        width: 45px;
        height: 45px;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    p{
        font-weight: 300;
    }
}