@import url('https://fonts.googleapis.com/css2?family=Raleway+Dots&display=swap');
@font-face {
    font-family: 'FontDot';
    src: url('../../assets/fonts/lcddot_tr.ttf') format('truetype');
}

#imersao-trilingue-page{
    background-image: url('../../assets/images/imersao-trilingue/Fundo.jpg');
    background-attachment: fixed;
    #hero-section-imersao-trilingue{    
        h1{
            color: #1780ff;
            font-size: 42px;
            font-family: 'Libre Baskerville', serif;
        }
    
        .box-image{
            height: 392px;
            width: 387px;
            position: relative;
            margin: auto;
    
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    
    }

    #banner1{
        .banner-1{
            background-color: #2A2B2E;
            box-shadow: 0px 10px 15px 0px #000000BF;
            width: 780px;
            height: 260px;
            border-radius: 20px;
    
            .banner-box-image{
                width: 400px;
                height: 400px;
    
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    position: relative;
                    top: -60px;
                    left: -15px;
                }
            }
    
            p{
            font-family: 'Libre Baskerville', serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 20.8px;
            letter-spacing: -0.019em;
            text-align: left;
            color: #F3F8F2;
            }
        }
    }

    #flag-section{
        .card-language{
            height: 280px;
            font-family: 'Libre Baskerville', serif;
            border-radius: 11px;
            padding-top: 20px;

            .card-inner{
                height: 100%;
                background-color: #D9D9D9;
                border-radius: 11px;
                padding: 1.2em;

                .box-image{
                    width: 78px;
                    height: 39px;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                h5{
                    font-family: 'Libre Baskerville', serif;
                    color: #2A2B2EEF;
                    font-weight: 700;
                    font-size: 36px;
                    line-height: 32px;
                }

                ul{
                    li{
                        list-style-type: circle;
                        color: #2A2B2EEF;
                    }
                }
            }
        }
    }

    #path-section{
        background-image: url('../../assets/images/imersao-trilingue/papper-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        font-family: 'Libre Baskerville', serif;


        h2{
            font-family: 'Libre Baskerville', serif;
            font-size: 48px;
        }

        .box-image{
            width: 438px;
            height: 438px;
            position: relative;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    #benefits-section{
        h2{
            font-family: 'Libre Baskerville', serif;
            font-size: 48px;
        }
        .card-benefits{
            height: 200px;
            background-color: #D9D9D9;
            border-radius: 11px;
            padding: 1.2em;

            .box-image{
                width: 38px;
                height: 38px;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            
            p{
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.019em;
                text-align: center;
                color: #2A2B2E;
            }
        }
    }

    #teachers-section{
        background-image: url('../../assets/images/imersao-trilingue/papper-bg.png');
        background-size: cover;
        background-repeat: no-repeat;

        h2{
            font-family: 'Libre Baskerville', serif;
            font-size: 48px;
        }

        .card-passport{
            width: 459px;
            height: 306px;
            background-color: #f6f7e8;
            box-shadow: 0px 10px 10px 0px #3030305d;

            h4{
                font-family: "FontDot", sans-serif;
            }

            .box-image{
                width: 125px;
                height: 140px;
                position: relative;
                margin: auto;
                border-radius: 10px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }

            p{
                text-align: justify;
            }

            .doted-divider{
                height: 10px;
                width: 100%;
                border: 3px dotted rgb(37, 37, 37);
            }
        }
    }

    #invest-section{

        h3, h5{
            font-family: 'Libre Baskerville', serif;
        }
        
        .box-image{
            width: 298px;
            height: 248px;
            position: relative;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

}
