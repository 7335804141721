/*---------------------------
    Social Share Style
---------------------------*/
.social-share {
    margin: -7px;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
        margin: 7px;
        a {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            width: 50px;
            height: 50px;
            line-height: 55px;
            display: inline-block;
            font-size: 18px;
            color: var(--color-white);
            transition: 0.4s;
            text-align: center;
            &:hover {
                background: var(--color-primary);
                color: var(--color-white);
            }
        }
    }

    &.bg-transparent {
        li {
            a {
                background: transparent;
                border: 1px solid rgba(45,40,78,0.1);
                color: var(--color-primary);
                border-radius: 100%;
                &:hover {
                    border-color: var(--color-primary);
                    background: var(--color-primary);
                    color: var(--color-white);
                }
            }
        }
    }

    &.icon-transparent {
        margin: 0 -15px;
        li {
            margin: 0 15px;
            a {
                background: transparent;
                height: auto;
                width: auto;
                color: var(--color-body);
                transition: 0.4s;
                line-height: initial;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
    &.border-style {
        margin: 0 -7px;
        li {
            margin: 0 7px;
            a {
                background: transparent;
                height: 40px;
                width: 40px;
                color: var(--color-primary);
                transition: 0.4s;
                line-height: 42px;
                text-align: center;
                border: 1px solid rgba(45,40,78,0.1);
                border-radius: 100%;
                font-size: 16px;
                &:hover {
                    color: var(--color-white);
                    background: var(--color-primary);
                    border-color: var(--color-primary);
                }
            }
        }
    }
    &.medium-size {
        li {
            a {
                width: 40px;
                height: 40px;
                line-height: 42px;
                font-size: 16px;
            }
        }
    }
}


