#comunicador-expressivo-social-section{
    background-color: #FFFFFF;

    h4{
        color: #18335e !important;
    }

    .card-depoimento{
        height: 360px;
        background-color: #FFFFFF;
        border-radius: 15px;
        padding: 1em;
        border: 2px solid #18335e;

        #depoimento{
            font-size: .87em !important;
        }

        .image-box{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        h6{
            color: #18335e !important;
        }

        p{
            color: #18335e;
            font-weight: 400;
        }
    }
}

@media screen and (max-width:490px) {
    #comunicador-expressivo-social-section{

        .card-depoimento{
            height: fit-content!important;

        }
    }
}