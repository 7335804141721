.gift-card-section{
    background-image: url("../../images/bg-gift-card.jpg");
    background-repeat: no-repeat;
    background-position: top center;
}

.card-gift-card{
    img{
        transform: scale(.95);
        cursor: pointer;
        transition: ease all .6s;

        &:hover{
            transform: scale(1);
        }
    }
}