@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#imersao-jose-hero-section{

    background: rgb(18,18,18);
    background: linear-gradient(90deg, rgba(18,18,18,1) 80%, rgba(55,46,34,1) 90%, rgba(149,115,75,1) 100%);

    h1{
        color: #B78C5A;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 58px !important;
    }

    h3{
        color: #B78C5A;
    }

    p{
        color: #ffffff;
        font-weight: 400;
    }

    .img-box{
        position: relative;
        bottom: 0;
        height: 600px;
        width: auto;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom;
        }
    }

    #divider{
        height: 15px;
        width: 100%;
        background: transparent linear-gradient(90deg, var(--unnamed-color-b78c5a) 0%, var(--unnamed-color-531a08) 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(90deg, #B78C5A 0%, #531A08 100%) 0% 0% no-repeat padding-box;
    }


}

@media screen and (max-width:780px) {
    #imersao-jose-hero-section{
        background: linear-gradient(90deg, rgba(18,18,18,1) 60%, rgba(55,46,34,1) 75%, rgba(149,115,75,1) 100%);
        h1{

            font-size: 38px !important;
        }
    }
}