#comunicador-expressivo-billing-section{
    background-color: black;

    p, h4, h3{
        color: #FFFFFF;
    }

    .btn-billing-box{
        width: 350px;
        display: flex;
        justify-content: center;
        align-items: center;

        .billing-btn{
            background-color: #FFFFFF !important;
            color: #18335e !important;
            font-weight: 400;
            border-radius: 11px;
            padding: .6em 1.2em;
            transition: ease all .6s;
    
            &:hover{
                background-color: #18335e !important;
                color: #FFFFFF !important;
            }
        }
    }
}