/*--------------------------
    Workshop Style One  
----------------------------*/

.workshop-style-1 {
    .workshop-inner {
        .description {
            color: var(--color-white);
            margin-top: 40px;
            margin-bottom: 40px;
            @media #{$md-layout} {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            @media #{$sm-layout} {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
}











