.loading {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-center;
    align-items: center;
    gap: 5px;
    justify-self: end;
    opacity: .8;
}

.load-box{
    height: 30px;
    width: 30px;
    border: 5px solid #1780FF;
    border-radius: 50%;
    border-top-color: #093366;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: spin 4s infinite linear;
}

@keyframes spin{
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.load-box-text {
    color: #1780FF;
}

@media screen and (max-width: 440px){
    .loading {
        padding-right: 5px;
    }
}