.title-imersao{
    color: #1E427A
}

@import './heroSection';
@import './aboutSection';
@import './ProgramSection';
@import './socialSection';
@import './ctaSection';
@import './supportSection';
@import './meetingsSection';
@import './benefitsSection';
@import './billingSection';
@import './faqSection';