.how-it-works-img-box{
  height: 500px;
  .how-it-works-img{
    width: 330px;
    height: 330px;  

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 18px;
    }

  }

  #image-1{
    position: relative;
    z-index: 2;
    left: 100px;
  }

  #image-2{
    position: relative;
    z-index: 1;
  }

  #image-3{
    position: relative;
    z-index: 2;
    left: 100px;
  }

  #image-4{
    position: relative;
    z-index: 4;
  }

}
@media screen and (max-width: 700px) {
  .how-it-works-img-box{
    height: 400px;

    .how-it-works-img{
      width: 200px;
      height: 200px;
    }
  }
}

.how-it-works-card-box{
  .how-it-works-card-item{
    width: 67px;
    height: 67px;
    border-radius: 20px;
    background: rgb(23,128,255);
    background: linear-gradient(153deg, rgba(23,128,255,1) 0%, rgba(7,85,180,1) 50%);
    position: relative;
    z-index: 3;
    right: -30px;
  }

  .how-it-works-card-text{
    width: 641px;
    height: 120px;
    border-radius: 25px;
    padding-left: 40px;
  }
}