.partiners-img-box{
  width: 250px;
  height: 250px;
  position: relative;
  
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  filter: grayscale(1);
  opacity: .3;
  transition: ease all .8s;

  &:hover{
    filter: grayscale(0);
    opacity: 1;
  }
}