#program-section {
    background-color: #121212;

    h4{
        color: #B78C5A !important;
    }

    .banner-1{
        background-color: #CBCBCB;
        border-radius: 20px;

        h5{
            color: #B78C5A !important;
            font-size: 36px !important;
        }

        li{
            list-style: none;
            color: #121212;
            font-size: 17px;
            .icon{
                color: #B78C5A;
            }
        }

        .banner1-image-box{
            height: 393px;
            position: relative;
            bottom: 0;

            img{
                position: relative;
                bottom: 219px;
            }
        }
    }

    .card-program{
        background-color: #333333;
        border-radius: 15px;
        padding: 2em 1em;

        .title-box{
            border-bottom: 2px solid #ffffff;
        }

    }

    .banner-2{
        width: 100%;
        max-width: 705px;
        height: 254px;
        background-color: #B78C5A;
        border-radius: 15px;
        padding: 1em;

        h5{
            font-size: 28px;
            color: #121212;
        }

        span, p {
            color: #ffffff;
        }

        p{
            font-size: 12px;
        }

        .banner2-image-box{
            height: 305px;
            width: auto;
            position: relative;
            bottom: 0;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: relative;
                bottom: 50px;
            }
        }

        .program-btn{
            background-color: #121212;
            border-radius: 11px;
            padding: .5em;
            color: #ffffff;
            font-weight: 500;
        }
    }
}