/*----------------------------
    Course Sidebar Styles 
-----------------------------*/ 
.edu-course-widget {
    background: var(--color-shade);
    padding: 20px 30px 30px;
    border-radius: 5px;
    .widget-title {
        border-bottom: 1px solid rgba(45,40,78,0.07);
        padding-bottom: 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }
    .content {
        margin-top: 30px;

        .edu-form-check {
            position: relative;
            & + .edu-form-check {
                margin-top: 25px;
            }
        }
    }
    &.widget-category {
        label {
            display: flex;
            justify-content: space-between;
        }
    }
    
}




