#comunicador-expressivo-benefits-section{
    background-color: black;

    h4, h6, p{
        color: #FFFFFF;
    }

    p{
        font-weight: 300;
    }

    .image-box{
        position: relative;
        bottom: -15px;
        height: 600px;
        width: auto;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}