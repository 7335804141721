@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

.transicao-carreira-hero {
    height: 650px;
    background-image: url(../../images/bg/hero-transicao-carreira.png);
    background-position: bottom right;
    background-color: black;

    h1{
        font-size: 45px;
        color: var(--color-primary);
    }

    .p-name{
        font-family: "Caveat", cursive;
        font-size: 48px;
        margin-top: 0;
        line-height: 48px;
    }
}

.p-name{
    font-family: "Caveat", cursive;
    font-size: 74px;
    margin-top: 0;
    line-height: 48px;
}

.banner-1-container{
    height: 7px;
    background-color: white;

    .banner{
        position: relative;
        bottom: 23px;
        z-index: 2;
        font-size: 20px;
        text-align: center;
        font-weight: 400;
        color: white;
        border-radius: 13px;
        background: rgb(225,50,138);
        background: linear-gradient(90deg, rgba(225,50,138,1) 0%, rgba(23,128,255,1) 100%);
    }

}

.program-section-container {
    background-color: #121212;

    .card{
        height: 360px;
        width: 100%;
        border-radius: 11px;
        padding: 15px;
        border: none;
    
        .content{
            position: relative;
            bottom: -130px;
    
            .icon{
                color: white;
                width: 70px;
                height: 70px;
            }
        
            h4{
                color: white;
                font-size: 20px;
                margin-bottom: 1em !important;
            }

            h5{
                color: white;
                font-size: 18px;
                margin-bottom: 0 !important;
                line-height: 20px;
            }
        }
    
        &#card-1{
            background-image: url(../../images/programa-carreira/etapa-1.png);
            background-size: cover;
        }
    
        &#card-2{
            background-image: url(../../images/programa-carreira/etapa-2.png);
            background-size: cover;
        }
    
        &#card-3{
            background-image: url(../../images/programa-carreira/etapa-4.png);
            background-size: cover;
        }
    
        &#card-4{
            background-image: url(../../images/programa-carreira/etapa-3.png);
            background-size: cover;
        }

        &#card-5{
            background-image: url(../../images/programa-carreira/bonus-1.png);
            background-size: cover;
        }
        &#card-6{
            background-image: url(../../images/programa-carreira/bonus-2.png);
            background-size: cover;
        }

        &#card-7{
            background-image: url(../../images/programa-carreira/bonus-3.png);
            background-size: cover;
        }
    }

    .bonus-container{
        background-color: #2A2B2E;
        border-radius: 15px;
        margin-top: 100px;

        .card{
            .content{
                h4{
                    font-size: 24px;
                }
            }
        }
    }

    .banner-2-container {
        background-color: var(--color-primary);
        position: relative;
        z-index: 2;
        bottom: 25px;
        border-radius: 25px;
        padding: {
            top: 5px;
            bottom: 5px;
            left: 10px;
            right: 10px
        };
        color: white;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
    }

    .box-price{
        width: fit-content;
        background-color: #2A2B2E;
        border-radius: 7px;
        padding: .5em;
        color: var(--color-primary);
        font-size: 24px;
        font-weight: 700;
    }

    .banner{
        margin-top: 50px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
        color: white;
        border-radius: 7px;
        background: rgb(225,50,138);
        background: linear-gradient(90deg, rgba(225,50,138,1) 0%, rgba(89, 52, 139,1) 100%);
        transition: ease all 1s;

        &:hover{
            background: var(--color-primary);
        }
    }
}

@media screen and (max-width:780px) {
    .transicao-carreira-hero {
        background-image: none;
        background: rgb(0,1,3);
        background: linear-gradient(21deg, rgba(0,1,3,1) 60%, rgba(1,155,187,1) 100%);
    }
}

