@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#imersao-comunicador-expressivo-hero-section{
    background: rgb(24,51,94);
    background: -moz-linear-gradient(172deg, rgba(24,51,94,1) 0%, rgba(2,2,2,1) 99%, rgba(3,3,3,1) 100%);
    background: -webkit-linear-gradient(172deg, rgba(24,51,94,1) 0%, rgba(2,2,2,1) 99%, rgba(3,3,3,1) 100%);
    background: linear-gradient(172deg, rgba(24,51,94,1) 0%, rgba(2,2,2,1) 99%, rgba(3,3,3,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#18335e",endColorstr="#030303",GradientType=1);
   
    h1{
        color: #FFFFFF;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 42px !important;
    }

    h3{
        color: #FFFFFF;
    }

    p{
        color: #ffffff;
        font-weight: 400;
    }

    .img-box-h1{
        position: relative;
        bottom: 0;
        height: 160px;
        width: auto;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    .img-box{
        position: relative;
        bottom: 0;
        height: 600px;
        width: auto;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom;
        }
    }

    .hero-btn{
        background-color: #FFFFFF;
        color: #18335e;
        font-weight: 400;
        border-radius: 11px;
        padding: .6em 1.2em;
        transition: ease all .6s;

        &:hover{
            background-color: #18335e;
            color: #FFFFFF;
        }
    }

    #banner-hero{
        background-color: #FFFFFF;

        h3{
            color: #18335e;
        }

        p{
            color: #18335e;

        }
    }


}

@media screen and (max-width: 420px) {
    #imersao-comunicador-expressivo-hero-section{
        h1{
            font-size: 32px !important;
        }
    }
    
}