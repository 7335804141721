#comunicador-expressivo-faq-section{
    background-color: #ffffff;

    h4, h6, p{
        color: #1E427A;
    }

    .card-faq-section{
        border: 1px solid #1E427A;
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.30);
        -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.30);
        box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.30);
        margin-bottom: 15px;
        padding: 1em;

        p{
            color: black;
            font-size: 14px;
            line-height: 14px;
            font-weight: 300;
        }
    }
}