section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: {
    left: 7%;
    right: 7%;
    top: 100px;
    bottom: 100px;
  };

  .box-l, .box-r{
    width: 48%;
    height: 100%;
  }
}



.impulso-h1{
  font-size: 50px;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.2em;
}

.box-image{
    width: 100%;
    height: 600px;
}

#image-robo{
  width: 550px;
  transition: ease all .8s;
  position: absolute;
  padding-bottom: 50px;
  animation-duration: 2s;
  animation-name: slidein;

}

@keyframes slidein {
  from {
    margin-left: 100%;
    transform: rotate(25deg);
  }

  to {
    margin-left: 0%;
    transform: rotate(0);
  }
}

#impulso-main{
  min-height: 650px;
  background-color: #1780FF;
  transition: ease all .7s;
  padding-bottom: 0;

  .box-l{
    p{
      color: #ECEEFF;
    }
  }
}

#section-2{
  background-image: url('../../images/bg/bg-ia-2.jpg');
  background-size: cover;
  background-attachment: fixed;
  gap: 5%;

  .image-container{
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  
    .image-box{
      width: 300px;
      height: 278px;
      border-radius: 41px;
      
      &#image-1{
        position: relative;
        z-index: 2;
        align-self: flex-start;
      }
  
      &#image-2{
        position: absolute;
        z-index: 1;
        background-color: transparent;
        border: 5px solid #1780FF;
      }

      &#image-3{
        position: relative;
        z-index: 3;
        align-self: flex-end;
      }
    }
  }
}

#section-3{
  background-color: #ECEEFF;
  justify-content: space-between;
  gap: 5%;

  .box-l, .box-r{
    justify-content: center;
  }

  .card-text-wrapp{
    text-align: left;
    width: 100%;
    h3{
      font-size: 32px;
      color: #1780FF;
      line-height: 1.1em;
    }

    h4{
      font-size: 18px;
      font-weight: 800;
      line-height: 19px;
    }

    p{
      font-size: 18px;
      line-height: 19px;
      text-align: left;
    }
  }

  .plans-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p{
      font-size: 18px;
      font-weight: 800;
      line-height: 19px;
      color: #093366;
    }
  }

  .plans-wrapp{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3%;

    p{
      font-size: 18px;
      font-weight: 600;
      line-height: 19px;
    }
  }

  .card-plan{
    min-width: 250px;
    width: 350px;
    min-height: 523px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 15px;
  
    .title-wrapp{
      background-color: #093366;
      width: 100%;
      height: 88px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px 15px 0 0;
  
      span{
        width: 193px;
        height: 28px;
        background-color: #1780FF;
        border-radius: 5px;
        color: #FFFFFF;
        position: absolute;
        z-index: 2;
        text-align: center;
        font-weight: 600;
        margin-bottom: 75px;
      }
  
      h2{
        font-size: 32px;
        font-weight: 500;
        color: #ECEEFF;
        margin-top: 15px;
      }
    }
  
    .content-wrapp{
      height: 170px;
      width: 100%;
      margin-top: 30px;
      padding: 30px;
  
      span{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;
        margin-bottom: 15px;
  
        p{
          width: 70%;
          margin: 0;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  
    .divider{
      width: 90%;
      height: 4px;
      background-color: #707070;
    }
  
    .price-wrapp{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 30px;
  
      h3{
        font-size: 16px;
        color: #1780FF;
      }
  
      P{
        font-size: 16px;
        padding: 15px 50px;
      }
    }
  }
}

.impulso-section{
  .box-r{
    .box-image{
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .image-big{
        width: 650px;
        height: 313px;
        box-shadow: 0px 3px 6px #0000004D;
      }
  
      .image-select{
        width: 650px;
        height: 80px;
        display: flex;
        justify-content: space-evenly;
        margin-top: 30px;
        gap: 10px;
  
        img{
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

.card-text{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .card-text-wrapp{
    max-width: 500px;

    h3{
      font-size: 36px;
      color: #1780FF;
      line-height: 1.1em;
    }

    p{

      text-align: left;
      margin-bottom: 100px;
    }
  }

  .button-wrapp{
    width: 674px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    a{
      width: 40%;
      background-color: #1780FF;
      font-size: 18px;
      font-weight: 600;
      padding: 8px 32px;
      border-radius: 10px;
      text-align: center;
      color: #FAFAFA;
      border: none;
      cursor: pointer;
      transition: ease all .8s;

      &:hover{
        background-color: #093366;
      }
    }

    #second{
      background-color: #093366;

      &:hover{
        background-color: #1780FF;
      }
    }

  }
}

.button{
  display: block;
  width: 100%;
  text-align: center;
  background-color: #1780FF;
  font-weight: 600;
  transition: ease all .8s;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 18px;
  color: #ECEEFF;

  &:hover{
    background-color: #093366;
    box-shadow: 0px 3px 6px #0000004D;
  }
}

.reverse{
  flex-direction: row-reverse;
  background-color: #f5f5f5;
}

#section-6{
  background-color: #1780FF;
  background-image: url('../../images/bg/bg-ia-3.jpg');
  background-size: cover;
  background-position: bottom;
  padding: {
    top: 0;
    bottom: 0;
  };

  .box-l{
    .card-text{
      .card-text-wrapp{

        span{
          p{
            color: #FFFFFF;
            margin-bottom: 0;
          }
        }

        h3{
          color: #f5f5f5;
        }
      }
    }
  }

  .impulso-form{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  
    form{
      width: 396px;
  
      h3{
        font-size: 26px;
        line-height: 1.1em;
        color: #093366;
        margin-bottom: 30px;
      }
  
      input{
        background-color: #ffffff;
        margin-bottom: 15px;
      }
  
      input[type="checkbox"]{
        position: relative;
        opacity: 1;
        height: 20px;
        width: 20px;
        margin-top: 1em;
      }
  
      .lgpd-container{
        height: 50px;
        display: flex;
        align-items: center;
        gap: 1em;
        margin-top: 25px;
        font-size: 12px;
        font-weight: 600;
        color: #093366;
        line-height: 13px;
        margin-bottom: 5px;
      }
    }
  }

  .box-r{
    display: flex;
    justify-content: center;
    align-items: center;

    .box-image{
      width: 100%;
      height: 750px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      #image-1{
        width: 318px;
        position: relative;
        z-index: 2;
        align-self: flex-end;
        left: -155px;
        bottom: 30px;
        img{
          width: 100%;
          height: 100%;
          
        }
      }

      #image-2{
        position: absolute;
        z-index: 1;
        width: 500px;
        height: 480px;
        img{
          width: 100%;
          height: 100%;

        }
      }
    }
  }
}

@media screen and (max-width: 1280px){
  section{
    flex-direction: column;
    padding: {
      top: 100px;
      bottom: 100px;
    };

    .box-l, .box-r{
      width: 100%;
    }
  }

  .plans-container{
    align-items: center;
    justify-content: center;
  }

  .box-image{
    justify-content: center;
  }

}

@media screen and (max-width: 821px){

  section{
    flex-direction: column;
    padding: {
      top: 100px;
      bottom: 100px;
    };

    .box-l, .box-r{
      width: 100%;
      padding: {
        top: 50px;
        bottom: 50px;
      };
    }
  }

  #section-2{
    .image-container{
      flex-direction: column;
    }
    .box-r{
      .card-text{
        .card-text-wrapp{
          max-width: 100%;
        }

        .button-wrapp{
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  .reverse{
    flex-direction: column;
  }

  
  .plans-container{
    align-items: center;
    justify-content: center;

    .plans-wrapp{
      flex-direction: column;
      .card-plan{
        margin-top: 30px;
      }
    }
  }

  #section-6{
    .box-r{
      .box-image{
        justify-content: flex-end;
      }
    }
  }

  .image-container{
    width: 100%;
    height: 500px;

    .image-box{
      width: 300px;
      height: 278px;  
    }
  }
}

@media screen and (max-width: 720px){
  section{
    padding: {
      top: 30px;
      bottom: 50px;
    };
  }

  .box-image{
    height: 365px;
  }

  #image-robo, #robo-bottom{
    width: 356px;
  }

  #section-2{
    .box-l{
      .image-container{
        flex-direction: column;
        .image-box{
          width: 230px;
          height: 210px;
        }
      }
    }

    .box-r, .box-l{
      .card-text{
        .button-wrapp{
          flex-direction: column;
          width: 100%;

          a{
            width: 100%;
          }
        }
      }
    }
  }

  #section-3{
    .box-r{
      .plans-container{
        .plans-wrapp{
          flex-direction: column;
          .card-plan{
            margin-top: 30px;
          }
        }
      }
    }
  }

  .impulso-section{
    flex-direction: column-reverse;
    padding: {
      top: 0;
      bottom: 0;
    };

    .box-r{
      .box-image{  
        .image-big{
          width: 383px;
          height: 183px;
        }
    
        .image-select{
          width: 383px;
          height: 54px;

          img{
            width: 30%;
          }
        }
      }
    }
  }

  #section-6{
    .box-r{
      .box-image {
        height: 100%;
        #image-1{
          left: -90px;
          bottom: -100px;
        }
        
        #image-2{
          width: 330px;
          height: 315px;

          img{
            object-fit: cover;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .button{
    display: block;
    text-align: center;
    padding: 5px;
  }

  #section-6{
    .box-r{
      .box-image{
        #image-1{
          width: 200px;
          left: -50%;
          bottom: -100px;
        }
      }
    }
  }
}

@media screen and (max-width: 416px){
  section{
    padding: {
      top: 10px;
      bottom: 30px;
    };
  }

  #section-3{
    .box-r{
      .plans-container{
        .plans-wrapp{
          flex-direction: column;
          .card-plan{
            margin-top: 20px;
          }
        }
      }
    }
  }

  .box-image{
    height: 295px;
  }

  #image-robo, #robo-bottom{
    width: 320px;
  }

  #section-2{
    .box-l{
      .image-container{
        flex-direction: column;
        .image-box{
          width: 230px;
          height: 210px;
        }
      }
    }

    .box-r, .box-l{
      .card-text{
        .button-wrapp{
          flex-direction: column;
          width: 100%;

          a{
            width: 100%;
          }
        }
      }
    }
  }

  .impulso-section{
    .box-r{
      .box-image{  
        .image-big{
          width: 305px;
          height: 148px;
        }
    
        .image-select{
          width: 305px;
          height: 54px;

          img{
            width: 30%;
          }
        }
      }
    }
  }

  #section-6{
    .box-r{
      .box-image{
    
        #image-1{
          width: 188px;
          left: -155px;
          bottom: -100px;
        }

        #image-2{
          width: 309px;
          height: 272px;
        }
      }
    }
  }
}
