.box-description-hero{
  p{
    color: #ffffff;
  }
}

.bhi-hero-card{
  width: 550px;
  padding: .5em 1em;
  background: var(--color-gold);
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .icon-bhi-card{
    color: var(--color-secondary);
  }

  p {
    color: var(--color-secondary);
    white-space: nowrap;
  }
}

.bhi-how-it-works{
  background-color: var(--color-bhi-blue);
}

.speakers-card{
  height: 450px;
}

.ocupation-section{
  height: 110px;
}

.invest-card-container{
  display: flex;
  .invest-feats{
    border-radius: 13px 0 0 13px;
    background-color: #ECEEFF;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .invest-cta{
    background-color: var(--color-gold);
    border-radius: 0 13px 13px 0;
    padding: 15px;
  }
  
}

@media screen and (max-width:990px) {
  
  .element-img{
    display: none;
  }
}

@media screen and (max-width:767px) {
  .bhi-hero-section{
    .inner{
      .wrapper{
        h1{
          font-size: 36px;
        }
      }
    }
  }

  .bhi-hero-card{
    width: 80%;
    p{
      font-size: 12px;
    }
  }

  .ocupation-section{
    height: 90px;
    p{
      font-size: 12px;
    }
  }

  .invest-card-container{
    flex-direction: column;
    width: 90%;
    margin: auto;
    .invest-feats{
      border-radius: 13px 13px 0 0;
    }
    .invest-cta{
      border-radius: 0 0 13px 13px;
      text-align: center;
      button{
        width: 90%;
      }
    }
    
  }
}