#cta-section{
    background-color: #CBCBCB;
    height: 400px;

    .cta-btn{
        width: 70%;
        background-color: #B78C5A;
        border: none;
        border-radius: 11px;
        color: #FFFFFF;
        padding: .5em 1em;
    }

    .img-box{
        height: 480px;
        width: auto;
        position: relative;
        bottom: 130px;

        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}