@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

#programa-barbara{
    background-image: url(../../../assets/images/programa-barbara/bg-1.png);
    background-size: cover;
    background-position: center top;
    font-family: "Inter", serif;

    h1, h2, h3, h4, h5, p, a, span, li {
        font-family: "Inter", serif;
        color: #ffffff;
    }

    #hero-section-barbara{
        #element-container {
            background-image: url(../../../assets/images/programa-barbara//element-1.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    
        .box-icon{
            width: 30px;
            height: 30px;
            margin: auto;
    
            .icon{
                font-size: 30px;
            }
        }
    }

    #program-section{
        background-color: transparent;

        .card{
            height: 450px;
            padding: 1em;
            background-color: #ffffff30;
            border: 1px solid #ffffff;
            border-radius: 11px;

            .box-image{
                height: 245px;
                position: relative;

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }

            #img-1{
                bottom: 23px;
            }

            #img-2{
                bottom: 50px;
            }
        }
    }

    #pricing-section{
        background-color: transparent;

        .card{
            height: 180px;
            padding: 1em;
            background-color: #ffffff30;
            border: 1px solid #ffffff;
            border-radius: 11px;

            .box-image{
                width: 138px;
                height: 40px;
                text-align: center;
                background-color: #ffffff;
                padding: .3em;
                border-radius: 11px;
                position: relative;
                top: -40px;
            }

            .button{
                background-color: #ffffff;
                text-wrap: nowrap;

                .icon{
                    color: #3C289B;
                    font-size: 1.4em;
                }
                
                span{
                    color: #3C289B;
                }
            }
        }
    }

}

@media screen and (max-width:780px) {
    #program-section{

        .card{

            #img-1{
                bottom: -17px !important;
            }

            #img-2{
                bottom: 12px !important;
            }

        }
    }

    #pricing-section{
        background-color: transparent;

        .card{
            height: 310px !important;

            .button{
                margin-top: 10px;
            }
        }
    }
}